<template>
	<div class="groupManage">
		<div class="notice">
			<p></p>
			<p>点此下载<a href="#" @click="pwdVisible = true" style="font-weight: bold;color: #fc7414;">微信个人号助手
					最近更新：2024-09-29</a>
			</p>
			<p>1、如未配置自动回复时间段，则任意时间均触发自动回复。</p>
			<p>2、如开启下单推送，则有用户下单时将向指定的群组中推送新用户下单消息。</p>
		</div>
		<div class="table">
			<el-form ref="form" :model="form" label-width="140px">
				<el-form-item label="自动回复时间段">
					<el-time-picker is-range v-model="timeRange" range-separator="至" start-placeholder="开始时间"
						value-format="HH:mm:ss" end-placeholder="结束时间" placeholder="选择时间范围" @change="confirmTimeRange">
					</el-time-picker>
				</el-form-item>
				<el-form-item label="已选时间段">
					<el-tag v-for="(item,index) in form.reply_time_arr" :key="index" closable="true"
						@close="removeTimeRange(index)" style="margin-right:10px">
						{{ item }}
					</el-tag>
				</el-form-item>
				<el-form-item label="未命中关键词" required>
					<el-radio-group v-model="form.no_hit_reply">
						<el-radio :label="0">不回复</el-radio>
						<el-radio :label="1">回复店铺首页链接</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="下单推送" required>
					<el-radio-group v-model="form.order_push">
						<el-radio :label="1">开启</el-radio>
						<el-radio :label="2">关闭</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="群组：" v-if="form.order_push == 1" required>
					<el-checkbox-group v-model="tmpTarget">
						<el-checkbox v-for="(item,index) in wxGroupList" :key="index" :label="item.id"
							:checked="item.checked" @change="checkGroup">{{ item.nickname
                   }}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click="confirmFn">确 定</el-button>
			</span>
		</div>
		<el-dialog title="密码" :visible.sync="pwdVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
			<el-form ref="formDown" :model="formDown" label-width="80px">
				<el-form-item label="登录密码" required>
					<el-input type="password" v-model="formDown.password" placeholder="请输入登录密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" required>
					<el-input type="password" v-model="formDown.password_confirmation" placeholder="请确认密码"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="pwdVisible = false">取 消</el-button>
				<el-button type="warning" @click="confirmDown">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import CryptoJS from 'crypto-js'
	var timestamp = new Date().getTime()
	export default {
		data() {
			return {
				timeRange: "",
				formDown: {},
				loading: true,
				wxList: [],
				wxGroupList: [],
				tmpTarget: [],
				form: {
					no_hit_reply: "1",
					order_push: "2",
					order_push_target: []
				},
				pwdVisible: false,
			}
		},
		mounted() {
			this.getIndex()
			// this.$nextTick(() => {
			// 	this.getWxAll()
			// })
		},
		methods: {
			checkGroup() {
				console.log(this.tmpTarget)
			},
			confirmDown() {
				this.$http.post(`/erp/v1/wx_groups/download`, {
					...this.formDown
				}).then((res) => {
					this.pwdVisible = false
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data)
					} else {
						this.$message.error(msg);
					}
				});
			},
			confirmTimeRange(val) {
				this.form.reply_time_arr.push(val[0] + "-" + val[1])
				console.log(this.form.reply_time_arr)
			},
			removeTimeRange(index) {
				this.form.reply_time_arr.splice(index, 1);
				console.log(this.form.reply_time_arr)
			},
			// 获取配置微信
			getIndex() {
				this.$http.post(`/erp/v1/wx_setting/index`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.form = data
						data.order_push_target.forEach(item => {
							this.tmpTarget.push(Number(item))
						})
						this.getWxAll()
						//console.log("form", this.form.order_push_target)
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				console.log(this.form);
				if (this.form.order_push == 1 && this.tmpTarget.length <= 0) {
					this.$message.error('请选择下单推送的群组');
					return
				}
				if (this.form.order_push == 1) {
					this.$set(this.form, "order_push_target", this.tmpTarget.join(","))
				}

				if (this.form.reply_time_arr.length > 0) {
					this.form.reply_time = this.form.reply_time_arr.join(",")
				}
				let url = '/erp/v1/wx_setting/modify'
				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						window.location.reload()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 微信群组
			getWxAll() {
				console.log("form1", this.form)
				let that = this
				that.wxUserList = []
				that.wxGroupList = []
				this.$http.post(`/erp/v1/wx_groups/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						that.wxList = data
						that.wxList.forEach(item => {
							if (item.type == 2) {
								that.wxGroupList.push(item)
							}
						})
					} else {
						that.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style>
	.notice {
		background: #fff;
		margin-bottom: 20px;
		padding: 20px;
		border-radius: 10px;
	}

	.avatar-uploader .el-upload {
		border: 1px dashed #FF720E;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #FF720E;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 125PX;
		height: 125PX;
		line-height: 125PX;
		text-align: center;
	}

	.avatar {
		width: 125PX;
		height: 125PX;
		display: block;
	}
</style>